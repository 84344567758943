import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RecoilEnv, RecoilRoot } from 'recoil'
import I18n from 'src/components/i18n'
import { getLocalLang, setLang } from 'src/utils/tools'
import '../assets/styles/index.scss'

// const i18nConfig = require('@/config/i18nConfig')

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false // 处理开发环境 duplicate atom key

// 客户端初始化挂载
// if (!isNode()) {
//   // 初始化
//   initConfig()
// }

function App(props: AppProps) {
  const { Component, pageProps } = props
  const router = useRouter()

  useEffect(() => {
    // initConfig()
    const { lang } = getLocalLang(window.location.pathname)
    window.YQ.configs.lang = lang
    setLang(lang)
  }, [])

  useEffect(() => {
    try {
      window.YQ.configs.filePath = location.pathname.replace(`/${window.YQ.configs.lang}`, '') || '/index'
      // 置顶 - 不记录历史位置
      window.history.scrollRestoration = 'manual'
    } catch (error) {
      console.log(error)
    }
  }, [router.pathname])

  // 全局变量初始化
  // const initConfig = () => {
  //   const { lang } = getLocalLang(window.location.pathname)
  //   window.YQ = window.YQ || {}
  //   window.YQ.configs = {
  //     adId: process.env.APP_ENV === 'production' ? '61953607' : '15712477', // 广告id
  //     lang: lang || 'en',
  //     env: process.env.APP_ENV,
  //     supportLang: i18nConfig?.supportLanguage?.slice(1)?.join(','),
  //     filePath: ''
  //   }
  //   setLang(lang)
  // }

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="renderer" content="webkit" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="author" content="admin@17track.net" />
        <meta name="apple-itunes-app" content="app-id=1004956012" />
        <meta name="google-play-app" content="app-id=yqtrack.app" />
        <meta name="msApplication-ID" content="17TRACK.17TRACK" />
        <meta name="msApplication-PackageFamilyName" content="17TRACK.17TRACK_vs3bzqqr3bhre" />
        <meta property="qc:admins" content="1027553633526174213366547" />
        {/*  */}
        <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
        <link rel="dns-prefetch" href="//www.google-analytics.com" />
        <link rel="shortcut icon" href="https://res.17track.net/global-v2/favicon.ico" />
        <link rel="image_src" href="http://res.17track.net/global-v2/imgs/oauth_image/share.png" />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_152x152.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_144x144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_120x120.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_114x114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="76x76"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_76x76.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_72x72.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="60x60"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_60x60.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="http://res.17track.net/global-v2/imgs/oauth_image/apple_touch_57x57.png"
        />
      </Head>
      <RecoilRoot>
        <I18n V5Front={pageProps.V5Front} locale={pageProps.locale} suppressHydrationWarning>
          {/* 全局消息提示 */}
          <ToastContainer
            limit={5}
            closeButton={false}
            position="top-center"
            theme="colored"
            hideProgressBar
            icon={false}
          />
          <Component {...pageProps} />
        </I18n>
      </RecoilRoot>
    </>
  )
}

export default App
