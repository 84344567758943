const dlv = require('dlv')

export default function (obj?: JSON) {
  let locale: string = ''
  const tree: any = obj || {}
  const init = (V5Front: any) => {
    for (const key in V5Front) {
      if (V5Front[key].items && !V5Front[key].items.itemsDict) {
        V5Front[key].items.itemsDict = {
          data: {},
          put: function (e: string | number, i: any) {
            this.data[e] = i
          },
          get: function (key: string | number) {
            try {
              return this.data[key]
            } catch (err) {
              return null
            }
          }
        }
        for (let i = 0, len = V5Front[key].items.length; i < len; i++) {
          V5Front[key].items.itemsDict.put(V5Front[key].items[i].key, V5Front[key].items[i])
        }
      }
    }
  }
  return {
    set(lang: string, table: JSON) {
      const V5Front = Object.assign(tree[lang] || {}, table)
      init(V5Front)
      tree[lang] = V5Front
    },

    locale(lang: string) {
      locale = lang
    },

    t(key: string, lang?: string) {
      const val = dlv(tree[lang || locale], key, '')
      return val
    }
  }
}
