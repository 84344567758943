/* eslint-disable */

// Shopify App 授权地址
export const SHOPIFY_APP_AUTH_INSTALL_LINK =
  'https://www.shopify.com/admin/oauth/authorize?client_id=4f2f4f1b9f45553f70cb1341a4410d79&scope=read_products,write_products,read_product_listings,read_orders,write_orders,read_all_orders,read_fulfillments,write_fulfillments,write_assigned_fulfillment_orders,read_assigned_fulfillment_orders,read_merchant_managed_fulfillment_orders,read_third_party_fulfillment_orders,write_merchant_managed_fulfillment_orders,write_third_party_fulfillment_orders,read_script_tags,write_script_tags,read_themes,write_themes&redirect_uri=https://trackcenter.17track.net/shop/shopify/'

// Shopify App 安装地址
export const SHOPIFY_APP_INSTALL_LINK =
  'https://apps.shopify.com/17track?utm_source=www.17track.net&utm_medium=addshop&utm_campaign=20230328-trackcenter-landing-page'

// 主站 跳转 shopify
export const SHOPIFY_APP_LINK = `https://apps.shopify.com/17track?utm_source=www.17track.net&utm_medium=link&utm_campaign=header`

// 客户案例轮播列表
export const customersSwiperList = [
  {
    id: 'customer_01',
    name: 'Offe Market',
    title: 'Offe Market Reduces WISMO requests by 87% with 17TRACK',
    img_url: ''
  },
  {
    id: 'customer_02',
    name: 'The Hat Shack',
    title: 'The Hat Shack Uses 17TRACK to save money on 96% of their Orders',
    img_url: ''
  },
  {
    id: 'customer_03',
    name: 'Black Diamonds',
    title: 'Black Diamonds New York Converts More Customers with 17TRACK',
    img_url: ''
  }
]

// 客户案例数据
export const customersGroup = [
  {
    id: 'customer_01',
    name: 'Offe Market',
    type: [5, 1],
    title: 'Offe Market Reduces WISMO requests by 87% with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_02',
    name: 'The Hat Shack',
    type: [5, 1],
    title: 'The Hat Shack Uses 17TRACK to save money on 96% of their Orders',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_03',
    name: 'Black Diamonds',
    type: [5],
    title: 'Black Diamonds New York Converts More Customers with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_04',
    name: 'Adelante',
    type: [5],
    title: 'Adelante Provides Delivery Assurances Returns with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_05',
    name: 'AristStyles',
    type: [5, 1],
    title: 'AristStyles Protects Delicate Dresses Against Logistics Risks with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_06',
    name: 'Soirees Boutique',
    type: [5, 1],
    title: '17TRACK Relieves Soirées’ Boutique of Post-Purchase Delivery Woes',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_07',
    name: 'Calico Rose',
    type: [5, 1],
    title: 'Calico Rose Eliminates Carrier Claims with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_08',
    name: 'Harmonia',
    type: [5, 1],
    title: 'Harmonia Resolves Anxiety Over Time-Sensitive Orders with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  }
]

// 客户详情页推荐案例
export const recommendCustomersGroup = [
  {
    id: 'customer_06',
    name: 'Soirees Boutique',
    type: [5, 1],
    title: '17TRACK Relieves Soirées’ Boutique of Post-Purchase Delivery Woes',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_05',
    name: 'AristStyles',
    type: [5, 1],
    title: 'AristStyles Protects Delicate Dresses Against Logistics Risks with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  },
  {
    id: 'customer_08',
    name: 'Harmonia',
    type: [5, 1],
    title: 'Harmonia Resolves Anxiety Over Time-Sensitive Orders with 17TRACK',
    img_url: '',
    time: 'Oct 28, 2022'
  }
]

// 映射便签
export const tagsGroup = [
  {
    key: 1,
    color: 'blue',
    title: 'Track'
  },
  {
    key: 2,
    color: 'orange',
    title: 'Return'
  },
  {
    key: 3,
    color: 'green',
    title: 'Buyer Protection'
  },
  {
    key: 4,
    color: 'cyan',
    title: '17TRACK Help Center'
  },
  {
    key: 5,
    color: 'green',
    title: 'Protection'
  },
  {
    key: 6,
    color: 'cyan',
    title: 'Notify'
  }
]

// 客户详情页内容数组
export const customersInfoGroup = [
  {
    id: 'customer_01',
    name: 'Offe Market',
    type: [],
    title: 'Offe Market Reduces WISMO requests by 87% with 17TRACK',
    text1: 'Challenge',
    text1_desc:
      'Offe Market found themselves devoting an exorbitant amount of time to shipping related concerns. Since they fulfill from so many different brands, a single order can originate from multiple vendors. This means that a simple WMP question — occurs on 15% of orders — might require 5+ emails to answer. Even worse is when items are lost or damaged, it can become a logistical nightmare to determine the responsible parties and file the proper claims.',
    text1_heading:
      '“We felt like our customer service team was just acting as a go-between for our customers and DHL.”',
    text2: 'Solution',
    text2_span1:
      'To address these challenges, Offe Market chose 17TRACK’s branded tracking page to answer WMP questions and Buyer Protection to simplify issue resolution. By installing a branded tracking page, Offe Market can empower their customers to locate orders in real time without multiple emails. Additionally, the ability to self-design the tracking page proved key to maintaining a seamless brand experience throughout the entire purchase and post-purchase journey.',
    text2_span2:
      'By offering the shopper-funded Buyer Protection, Offe Market could bypass the time consuming resolution process of working through the relevant vendors. Instead, shoppers could just report their issues and receive compensation through a single workflow. Buyer Protection also appealed to customers, by giving them the confidence that any potential shipping issues would be promptly and completely resolved.',
    text3: 'Results and Next Steps',
    text3_desc1:
      'Since incorporating a branded tracking page and Buyer Protection from 17TRACK, Offe Market has seen an astounding 87% drop in shipping queries. This has saved them a tremendous amount of time they would otherwise have spent communicating with carriers and insurance companies to resolve each shipping issue. Offe Market’s customers clearly enjoy 17TRACK’s new features as well since 83% of shoppers added Buyer Production since its introduction.',
    text3_heading:
      '“I really like that the customer can just go in and track their own order and hopefully find their own insights to where their product might be or why it’s delayed or missing. It seems like they can resolve it on their own versus me spending my time talking to the post office or these insurance companies to try to get my money back if something was lost or damaged.”',
    author: 'Rachel Gannon',
    identity: 'Founder & CEO of Offe Market',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'Los Angeles, CA',
    company_industry: 'Fashion & Apparel',
    company_business_model: 'Marketplace/Overstock Liquidation',
    company_technologies: 'Shopify, Affirm, Klarna, Klaviyo, Seel Return Assurance',
    company_desc:
      'Offe Market offers shoppers a range of overstocked and end-of-season products at affordable prices (anywhere from 30-80% off) from popular, high-quality brands. They aim to keep quality clothing out of landfills by offering a curated shopping experience for surplus items. Since starting in 2019, Offe Market has added over 30 brands to their marketplace, including well known names like Levi’s and Vans.',
    results_item: [
      { title: 'Buyer Protection Attach Rate', data: '83%' },
      { title: 'Drop in WISMO Question', data: '87%' }
    ]
  },
  {
    id: 'customer_02',
    name: 'The Hat Shack',
    type: ['post-purchase experience', 'Reduce support cost', 'jewlery', 'D2C'],
    title: 'The Hat Shack Uses 17TRACK to save money on 96% of their Orders',
    text1: 'Challenge',
    text1_desc:
      'Hat Shack found that their customers frequently received crumpled hats because their packages would get caught in the postal processing machines. They tried sturdier boxes, and third-party package protection, but these solutions proved too expensive, and Hat Shack still needed to process individual claims, creating extra customer service work that burdened their small team.',
    text1_heading:
      "“For every 20 packages, at least one or two arrived completely crushed. That's the main thing for me. I was using another third-party shipping protection. But for that product, I had to pay out of my pocket. On top of that, I also had to follow claims and everything all by myself.”",
    text2: 'Solution',
    text2_span1:
      'By adding Buyer Protection from 17TRACK, Hat Shack was able to offset the costs and customer service headaches of their regularly occurring shipment damages. This value-added protection is paid for by their customers at a truly negligible cost per order. More importantly, in the event of loss, damage or delay, their customers could easily resolve the issue by themselves through the Buyer Protection resolution process.',
    text2_span2:
      'Hat Shack’s customers also benefited from 17TRACK’s package status notifications, they added the standard post-purchase notifications to their user experience. This way customers would know when their package is shipped out or the status changes without needing to email customer support.',
    text3: 'Results and Next Steps',
    text3_desc1:
      'Hat Shack customers have overwhelmingly embraced the peace of mind offered by Buyer Protection — 96% of orders now add the option at checkout. Moreover, with 17TRACK’s automatic email notifications, shipping queries have dropped dramatically by 50%, significantly decreasing Hat Shack’s customer support costs.',
    text3_heading:
      '“With Buyer Protection, customers can be their own advocate, and we don’t have to be in between. Additionally, shipping notifications have decreased my logistics questions by half. All in all, adding 17TRACK and Buyer Protection has increased the credibility of my brand.”',
    author: 'Freddy Ramirez',
    identity: 'Owner & CEO of The Hat Shack',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'Bakersfield, CA',
    company_industry: 'Headwear',
    company_business_model: 'D2C Brand / Made to Order',
    company_technologies: 'Shopify, Affirm, Seel Return Assurance',
    company_desc:
      'The Hat Shack offers a variety of headwear for men and women, specializing in made to order straw and felt cowboy hats, in addition to hat care products, outerwear, and other accessories. They sell from both their in-store location in Bakersfield, California and their Shopify store. Since their online debut in 2020, The Hat Shack has earned more than 10,000 loyal customers.',
    results_item: [
      { title: 'Buyer Protection Attach Rate', data: '96%' },
      { title: 'Drop in WISMO Question', data: '50%' }
    ]
  },
  {
    id: 'customer_03',
    name: 'Black Diamonds',
    type: [],
    title: 'Black Diamonds New York Converts More Customers with 17TRACK',
    text1: 'Challenge',
    text1_desc:
      'Package protection covering loss, damage, and delay is not something new to Black Diamonds, who tremendously value the entirety of their customers’ experience. However, the previous service they used for package protection was expensive, adding prohibitive costs to customers that hurt conversion rates. They still wanted to offer package protection but needed to keep rates reasonable, and they also didn’t want to add another tool to their site.',
    text1_heading:
      '“We used another package protection before a while back and felt it was expensive. It was so expensive that it affected our conversion in a negative way. So we stopped offering that when we learned 17TRACK offers Buyer Protection.”',
    text2: 'Solution',
    text2_span1:
      'Black Diamonds had already downloaded the 17TRACK shopify app to enhance their post-purchase experience. Upon learning that they had just introduced Buyer Protection, Black Diamonds signed up for and integrated the beta version, seeing immediate improvement. The price for their shoppers was significantly lower thanks to advanced algorithms that better underwrite the risks of loss, damage, and delay. They also were able to add the widget with just a few clicks and didn’t need to incorporate any new tools.',
    text2_span2: '',
    text3: 'Results and Next Steps',
    text3_desc1:
      'After 17TRACK’s Buyer Protection went live on Black Diamonds’ cart page, 86% of their customers added the new protection option. More importantly, cart conversion improved and shoppers ultimately bought more during the holidays as their concerns over package loss, damage were now assuaged.',
    text3_heading:
      '“User experience with Buyer Protection is really good. Fees are significantly cheaper (than the previous app used), and customers can easily enroll or dismiss. The experience for us is also super friendly, as we don’t have to add anything new to our tech stack.”',
    author: 'Rebecca Hall',
    identity: 'Black Diamonds New York',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'New York City, NY',
    company_industry: 'Jewelry',
    company_business_model: 'D2C Brand / Made to Order',
    company_technologies: 'Shopify, Affirm, Klaviyo, Seel Return Assurance',
    company_desc:
      'Black Diamonds New York began in 2013 in Okinawa, Japan where they served the US military community with affordable wedding and engagement rings made from high-quality materials and conflict-free diamonds. Their products are inspired by a range of influences, including art, the latest trends, modern goth fashion, and video games. To date, they have completed a staggering 600,000 orders.',
    results_item: [{ title: 'Buyer Protection Attach Rate', data: '86%' }]
  },
  {
    id: 'customer_04',
    name: 'Adelante',
    type: [],
    title: 'Adelante Provides Delivery Assurances Returns with 17TRACK',
    text1: 'Challenge',
    text1_desc:
      'Due to the nature of made-to-order shoes, Adelante’s customers often like to ensure their orders arrive at the right location, requesting services like signature upon delivery.  Ultimately, this led to significantly more customer service requests for changed delivery dates and missed deliveries. Adelante needed a solution to comfort their customers without creating additional work for the marketing and customer service teams.',
    text1_heading:
      '“We felt like our customer service team was just acting as a go-between for our customers and DHL.”',
    text2: 'Solution',
    text2_span1:
      'After identifying that their customers desired delivery assurances, Adelante worked with 17TRACK to add Buyer Protection to its Shopify Store. This gives shoppers the confidence to place their orders without the need for complex deliveries.',
    text2_span2:
      'Adelante chose to add the Buyer Protection Widget to their Shopify Cart. The widget enables customers to add Buyer Protection for a small, transparent fee at checkout. Most importantly, the buyer protection widget does not require Adelante to remove or replace any of their existing tech stack, making installation a breeze.',
    text3: 'Results and Next Steps',
    text3_desc1:
      'Since going live with 17TRACK, Adelante has seen 82% of their customers add Buyer Protection, eliminating the need for the costly Signature upon Delivery option.  Based on the ease of working with Buyer Protection, Adelante is looking into incorporating the 17TRACK’s Branded Tracking Page into their site.',
    text3_heading:
      '“Buyer protection truly augments our customer service, it allows us to reassure our customers without adding additional work for our service teams.”',
    author: 'Michael Pelzer',
    identity: 'CEO of Soirées’ Boutique',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'Cambridge, MA',
    company_industry: 'Footwear',
    company_business_model: 'D2C Brand / Private Label Manufacturer',
    company_technologies: 'Shopify, Klaviyo, Afterpay, Seel Return Assurance',
    company_desc:
      'Adelante Shoe Co. was founded by Peter Sacco with the goal of spreading economic prosperity throughout Latin America. They do this by manufacturing high-quality, made-to-order shoes at a fraction of market rates. The company primarily sells to the United States through multiple channels, including a D2C Shopify Store and Private Label manufacturing. Today, Adelante proudly services their 10,000 customers from their locations in Massachusetts and Guatemala.',
    results_item: [{ title: 'Buyer Protection Attach Rate', data: '82%' }]
  },
  {
    id: 'customer_05',
    name: 'AristStyles',
    type: ['Protection', 'Branded tracking page'],
    title: 'AristStyles Protects Delicate Dresses Against Logistics Risks with 17TRACK',
    text1: 'Challenge',
    text1_desc:
      'There’s a lot of uncertainty when it comes to buying a dress online, and the decision can be even more difficult when you’re dealing with a lesser known retailer. AristStyles needed a way to better establish their credibility and assure their customers any and all shipping problems would be taken care of. With all the complexities of special events apparel, they sought a solution that would guarantee shoppers protection against damage to their delicate products and carrier delays during peak season.',
    text1_heading:
      '“It’s hard to buy a dress online. Sometimes the dresses we sell are fragile and get damaged in the shipping. We’re kind of entrusting the carriers with customer satisfaction.”',
    text2: 'Solution',
    text2_span1:
      'AristStyles chose to install 17TRACK’s Buyer Protection and Branded tracking page, reassuring their customers and delineating fulfillment responsibilities. The combination of order tracking and buyer protection clearly demonstrates to the shopper when issues are the carrier’s fault, thus decreasing fulfillment complaints.',
    text2_span2:
      'The installation was painless. AristStyles was able to find the 17TRACK app on the Shopify App store and after a few clicks had the app installed. From there, they designed their new package tracking page to match the AristStyles brand and set a go-live date for Buyer Protection.',
    text3: 'Results and Next Steps',
    text3_desc1:
      'The one-stop shop experience of 17TRACK allowed AristStyles to complete the installation in under an hour. Immediately after, the client noticed an increase in site engagement due to usage of the new tracking page. Shoppers were coming back to the site to check their order status instead of creating customer service tickets. 17TRACK’s combination of Buyer Protection and visible tracking gave AristStyles’ customers greater autonomy over their orders and greater peace of mind.',
    text3_heading:
      "“If anything happens, our business is protected and customers know they're going to get their money back. There is a lot of respect, confidence, and peace of mind on both ends.”",
    author: 'Farzana Singh',
    identity: 'Owner of AristStyles',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'Jamaica, NY',
    company_industry: 'Specialty Event Apparel',
    company_business_model: 'Specialty Retailer',
    company_technologies: 'Shopify, Affirm, Seel Return Assurance',
    company_desc:
      'Founded in 2020, AristStyles was born out of a desire to find unique dresses that are not just fashionable but extremely feminine, elegant, and sexy. To provide high-end couture dresses at the best possible price and with the best service, they offer rich choices of designs from Cinderella Divine, Andrea & Leo Couture, L’ATISTE, GLS Collective, and many more. AristStyles helps create beautiful and lasting memories for every one of their customers on their special days.',
    results_item: []
  },
  {
    id: 'customer_06',
    name: 'Soirees Boutique',
    type: ['Protection', 'Track'],
    title: '17TRACK Relieves Soirées’ Boutique of Post-Purchase Delivery Woes',
    text1: 'Challenge',
    text1_desc:
      'Building trust with customers is pivotal for any business, however, delivery delays happen and orders sometimes get damaged or lost in transit. As a drop shipping business that relies on fulfillment from third parties that’s something Soirées’ Boutique has no control of. This not only leads to lots of customer service tickets but also hurts their hard-earned credibility.',
    text1_heading:
      "“AliExpress has a habit of delaying packages. There's really no telling when they say they may deliver on one day, but then sometimes, you know, things happen.”",
    text2: 'Solution',
    text2_span1:
      'To ease the workload of their customer service team, Soirées’ Boutique installed 17TRACK, and easily embedded a tracking page on their site. Allowing their customers to look up shipping information by themselves.',
    text2_span2:
      'Better still, the newly launched Buyer Protection widget in 17TRACK was easy to add to their Shopify checkout. Shoppers who add Buyer Protection have their orders fully protected against loss, damage, and delay—all delivery woes that have been perennially plaguing Soirées’ Boutique.',
    text3: 'Results and Next Steps',
    text3_desc1:
      'After installing 17TRACK and enabling the Buyer Protection feature, questions about shipments dropped for Soirées’ Boutique by a full 99%. With Buyer Protection, common shipping issues are quickly resolved for customers, transforming issues into positive experiences.',
    text3_desc2:
      'Soirées’ Boutique is now planning to enable 17TRACK’s email notification feature, which updates customers on package status changes, to further improve their shoppers’ post-purchase experience.',
    text3_heading:
      '“With 17TRACK’s tracking page and Buyer Protection widget in place, ‘Where’s my package?’ questions fell by 99 percent. Installation was quick and easy. I was able to enjoy the fancy technology without any tech expertise.”',
    author: 'Miriame Maxi',
    identity: 'CEO of Soirées’ Boutique',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'West Palm Beach, FL',
    company_industry: 'Fashion & Apparel',
    company_business_model: 'White Label Dropshipping',
    company_technologies: 'Shopify, Affirm, Seel Return Assurance',
    company_desc:
      'Soirées’ Boutique is a one-stop destination for all types of fashion needs. As a white label drop shipping business, they offer an array of trendy clothing, shoes, purses and accessories for women to upgrade their wardrobe.',
    results_item: []
  },
  {
    id: 'customer_07',
    name: 'Calico Rose',
    type: ['Protection', 'Branded tracking page'],
    title: 'Calico Rose Eliminates Carrier Claims with 17TRACK',
    text1: 'Challenge',
    text1_desc:
      'As a business that values their relationship with their customers and that endeavors to create a satisfying buying experience, Calico Rose packages and ships out everything themselves to ensure high quality fulfillment. Unfortunately, their packages are regularly opened in transit and their products are sometimes damaged or misplaced through no fault of their own. As the issue lies with the carrier, Calico Rose sympathizes with their customers but can only ask them to file a claim with the carrier. Not all such claims are resolved well in the end.',
    text1_heading:
      '“You know for a fact that all the items are in that package when you send it out. It gets opened by the carrier for security or verification purposes. And sometimes they leave an item out when they repack.”',
    text2: 'Solution',
    text2_span1:
      'To provide their customers protection from shipping mishaps, Calico Rose turned to 17TRACK. Buyer Protection within the 17TRACK Shopify app gives their customers the ultimate peace of mind. For a tiny fee, they can rest assured knowing they’re covered whether an item has gone missing or arrives damaged, and setting up the widget on the cart page took Calico Rose just a few clicks.',
    text2_span2:
      'Furthermore, 17TRACK’s tracking page provides Calico Rose’s customers complete visibility into the shipping process. Customers can instantly check the progress of their orders anytime, anywhere, without having to call support or wait for answers.',
    text3: 'Results and Next Steps',
    text3_desc1:
      'Since adding 17TRACK’s Buyer Protection and tracking page, “Where is my package?” questions have dropped from being the store’s number one customer service request to a sum total of zero. With the Buyer Protection widget, Calico Rose can assure customers any delivery issues will be resolved and keep them informed throughout every step of the shipping process.',
    text3_heading:
      "“I haven't noticed any new shipping questions since adding the tracking page. And Buyer Protection removes the risk from both my customers and me without forcing anybody to go through the fortress of the claims processes with carriers, which we previously found a difficult situation.”",
    author: 'Jordin, Owner',
    identity: 'Founder of Calico Rose',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'West Point, IN',
    company_industry: 'Fashion & Apparel',
    company_business_model: 'D2C Ecommerce',
    company_technologies: 'Shopify, Affirm, Seel Return Assurance',
    company_desc:
      "Founded in Lafayette, Indiana, Calico Rose Boutique has become a premier destination for women’s casual apparel. Providing everything from dresses to shoes to hoodies, the store's range of fashion choices are popular with its 10,000 loyal customers.",
    results_item: []
  },
  {
    id: 'customer_08',
    name: 'Harmonia',
    type: ['Protection', 'branded tracking page'],
    title: 'Harmonia Resolves Anxiety Over Time-Sensitive Orders with 17TRACK',
    text1: 'Challenge',
    text1_desc:
      'Since Harmonia mostly sells outfits for specific events, if the shipment is late or lost Harmonia’s customers will miss out on wearing their outfits. Harmonia needed a solution to make their customers whole when delivery issues arose. Additionally, their small team would get backed up with communications between the carrier and their customer whenever there was an issue.',
    text1_heading:
      '“The most important thing is when something goes wrong. The customer is already pissed off about losing their package, so how easy is it to rectify this? That’s what’s going to make the difference.”',
    text2: 'Solution',
    text2_span1:
      'Harmonia managed to resolve the headaches caused by shipping problems by installing two features from 17TRACK. The branded tracking page gave their customers real-time visibility into the status of their time-sensitive orders, relieving them of anxiety and answering their questions without having to call customer support.',
    text2_span2:
      'Meanwhile, Buyer Protection assures their customers they will be compensated in the event packages do go missing or arrive damaged, not only relieving the team of customer service complaints but also earning buying confidence and long-term loyalty from their shoppers.',
    text3: 'Results and Next Steps',
    text3_desc1:
      'The Harmonia team is relieved that the branded tracking page keeps their customers on their own site without having to look elsewhere for answers or feeling confused or disoriented. Buyer Protection has greatly boosted customer confidence as they know any shipping problems will be quickly resolved.',
    text3_heading:
      '“The branded tracking page offers a consistent experience for my customers, which is key to us. Buyer Protection definitely adds to greater customer confidence, knowing that they are covered against loss, damage, and delay.”',
    author: 'Daniela Watanabe',
    identity: 'Founder & Creative Director of Harmonia',
    share_title: 'Share this Story',
    share_facebook: 'Oct 28, 2022',
    share_twitter: 'Oct 28, 2022',
    share_linkedin: 'Oct 28, 2022',
    share_youtube: 'Oct 28, 2022',
    company_info: 'Company Info',
    company_headquarters: 'New York City, NY',
    company_industry: 'Festival Apparel & Costumes',
    company_business_model: 'D2C Ecommerce',
    company_technologies: 'Shopify, Affirm, Ecocart, Seel Return Assurance',
    company_desc:
      'Having started as a small Etsy shop in 2015, Harmonia is now a thriving fashion business on Wall Street. They cater to a niche, trendsetting clientele, offering diversely themed collections under such labels as Ethereal, Oriental, Burlesque, Futuristic, and Tribal Futurism. More recently, Harmonia launched their latest chic collection at the 2022 New York Fashion Week.',
    results_item: []
  }
]
