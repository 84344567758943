// import carrier from '@/src/utils/carrier'
import { Fragment, createContext, useEffect, useRef, useState } from 'react'
import carriers from 'src/utils/carriers'
import rosetta from './initI18n'

const i18n = rosetta()

export const defaultLanguage = 'en'

export const I18nContext = createContext({})

// default language
i18n.locale(defaultLanguage)

export default function I18n({ children, locale, V5Front }: any) {
  const activeLocaleRef = useRef(locale || defaultLanguage)
  const [, setTick] = useState(0)
  const firstRender = useRef(true)

  const i18nFormat = (str: string, args: Array<any>) => {
    if (!str) return ''
    const splitArr = str.split(/(\{[0-9]?\})/g).filter((item) => item)
    let index = 0
    let hasDom = false
    const actionText = splitArr.map((item) => {
      if (/\{[0-9]?\}/g.test(item)) {
        index += 1
        const i = args[index - 1]
        if (typeof i === 'object') hasDom = true
        return i
      } else {
        return item
      }
    })
    return hasDom ? (
      <>
        {actionText.map((item: any, i: number) => (
          <Fragment key={i}>{item}</Fragment>
        ))}
      </>
    ) : (
      actionText.join('')
    )
  }

  const i18nMap = {
    activeLocale: activeLocaleRef.current,
    $t: (key: string, lang?: string) => {
      return i18n.t(key, lang)
    },
    locale: (l: string, dict: any) => {
      i18n.locale(l)
      activeLocaleRef.current = l
      if (dict) {
        i18n.set(l, dict)
      }
      setTick((tick) => tick + 1)
    },
    i18nFormat,
    /**
     *@Description: 数据字符串替换方法
     *@param {String} str  被替换字符串
     *@param {Array/String/Object} replaceStrArr 替换值数组，这里采取映射替换全部，
     */
    i18nFormatAll: (str: string, replaceStrArr: any) => {
      let replaceNewStr: string | JSX.Element = str
      try {
        // 不存在被替换字符串时，直接return
        if (!replaceNewStr) {
          console.log('注意传入了空值', replaceNewStr)
          return ''
        }

        if (Object.prototype.toString.call(replaceStrArr) === '[object Array]') {
          replaceNewStr = i18nFormat(str, replaceStrArr)
        } else if (Object.prototype.toString.call(replaceStrArr) === '[object Object]') {
          replaceNewStr = replaceNewStr.replace(/{(\w+)}/g, function (match, key) {
            // 处理部分需要特殊有链接的词条替换
            if (typeof replaceStrArr[key + 'Url'] !== 'undefined' && typeof replaceStrArr[key] !== 'undefined') {
              return `<a target='_blank' href='${replaceStrArr[key + 'Url']}'>${replaceStrArr[key]}</a>`
            } else {
              return typeof replaceStrArr[key] !== 'undefined' ? replaceStrArr[key] : match
            }
          })
        } else {
          replaceNewStr = replaceNewStr.replace(/\{0\}/g, replaceStrArr)
        }
      } catch (error) {
        console.log('FormatAll replacement failed: ', { replaceNewStr, replaceStrArr })
      }

      return replaceNewStr
    }
  }

  // for initial SSR render
  if (locale && firstRender.current === true) {
    firstRender.current = false
    i18nMap.locale(locale, V5Front)
  }

  // if (typeof window !== 'undefined') {
  //   if (locale && V5Front) {
  //     i18n.set(locale, V5Front)
  //     if (!window.YQ.$carriers) {
  //       // 初始化运输商资源
  //       window.YQ.$carriers = carriers
  //       window.YQ.$carriers.updateCarrier(i18nMap.$t)
  //     }
  //   }
  // }

  useEffect(() => {
    try {
      if (locale) {
        i18nMap.locale(locale, V5Front)
        if (!window.YQ.$carriers) {
          // 初始化运输商资源
          window.YQ.$carriers = carriers
          window.YQ.$carriers.updateCarrier(i18nMap.$t)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [V5Front, locale])

  return <I18nContext.Provider value={i18nMap}>{children}</I18nContext.Provider>
}
