import i18nConfig from 'config/i18nConfig'
import jsCookie from 'js-cookie'
import { SHOPIFY_APP_INSTALL_LINK } from 'src/constants/customers'
import { LANG_COOKIE_KEY } from 'src/constants/i18n'
import { _YQ_RC_ } from 'src/constants/user'
import { GlobalGA4TagType } from 'src/types'
/**
 * 获取语言
 * @param path
 * @returns { lang, isI18n }
 */
export const getLocalLang = (path: string): { lang: string; isI18n: boolean; v5_Culture: string } => {
  const pathArr: Array<string> = path.split('/').filter((item: string) => item)
  const firstPath: string = pathArr && pathArr.length ? pathArr[0] : ''
  const isI18n: boolean = i18nConfig.supportLanguage.indexOf(firstPath) !== -1
  const v5_Culture = jsCookie.get(LANG_COOKIE_KEY) || ''
  const lang = isI18n ? firstPath : v5_Culture || 'en'
  return {
    lang,
    isI18n,
    v5_Culture
  }
}

/**
 * 设置语言
 * @param lang
 */
export const setLang = (lang: string) => {
  if (lang !== jsCookie.get(LANG_COOKIE_KEY)) {
    jsCookie.remove(LANG_COOKIE_KEY, { domain: 'm.17track.net', path: '/' })
    jsCookie.remove(LANG_COOKIE_KEY, { domain: 't.17track.net', path: '/' })
    jsCookie.remove(LANG_COOKIE_KEY, { path: '/' })
    jsCookie.remove(LANG_COOKIE_KEY)
    jsCookie.set(LANG_COOKIE_KEY, lang, { domain: '17track.net', expires: 365, path: '/' })
  }
}

/**
 * 获取用户uid
 * @returns
 */
export const getUid = () => {
  return jsCookie.get(_YQ_RC_)
}

/**
 * 移除用户uid
 * @returns
 */
export const removeUid = () => {
  jsCookie.remove(_YQ_RC_)
}

/**
 * 判断是否node环境
 * @returns boolean
 */
export const isNode = () => {
  return typeof window === 'undefined'
}

/**
 * 是否为微信
 * @param userAgent
 * @returns
 */
export const isWeixin = (userAgent) => {
  return userAgent.indexOf('micromessenger') !== -1
}

/**
 * 是否为ios
 * @param userAgent
 * @returns
 */
export const isIos = (userAgent) => {
  const reg = /(ios|ipad|iphone)/
  return reg.test(userAgent) || (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document)
}

/**
 * 是否为android
 * @param userAgent
 * @returns
 */
export const isAndroid = (userAgent) => {
  return /android/.test(userAgent)
}

/**
 * 获取谷歌分析gtag
 */
export function getGlobalTag(type: GlobalGA4TagType) {
  // if (['index', 'brand'].includes(type)) {
  //   return window.YQ.configs.env === 'production' ? 'G-DFLC2LRX2J' : 'G-V4LE7Z0DDV'
  // } else if (['carriers-index'].includes(type)) {
  //   return window.YQ.configs.env === 'production' ? 'G-2LXGZVDHF3' : 'G-V4LE7Z0DDV'
  // }

  // return window.YQ.configs.env === 'production' ? 'G-ZD7NTY7WYK' : 'G-V4LE7Z0DDV'
  if (['carriers-index'].includes(type)) {
    return window.YQ.configs.env === 'production' ? 'G-2LXGZVDHF3' : 'G-V4LE7Z0DDV'
  }

  return window.YQ.configs.env === 'production' ? 'G-DFLC2LRX2J' : 'G-V4LE7Z0DDV'
}

/**
 * 跳转到Shopify App 安装页面
 */
export function handleToShopify() {
  window.ga?.sendEvent?.('事件操作', 'landing page-跳转shopify', 'Track Center落地页安装点击次数')
  // 谷歌广告转化
  window.ga?.sendGaEvent?.('conversion', {
    send_to: 'AW-16826827968/r_ycCJ_74IwaEMCJ1Nc-' // 谷歌广告转化ID
  })
  window.location.href = SHOPIFY_APP_INSTALL_LINK
}

/**
 * 是否支持webp
 * @returns
 */
export const supportsWebP = () => {
  if (!process.browser) return false
  const elem = document.createElement('canvas')

  if (elem.getContext && elem.getContext('2d')) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
  }

  return false
}

// 查询全运输商词条数据
export const toFilterSpace = (_str) => {
  try {
    // 这是问题词条的key，根据指定数组顺序返回过滤的数组
    return _str?.toLowerCase()?.replace(/(^\s+)|(\s+$)|\s+/g, '-')
  } catch (error) {
    console.log(error)
  }
}

/**
 *@Description: 用户将某个数据上的某些自己想要找的值组合起来返回
 *@param {String} data 源数据
 *@param {String} keyArr 想要得到元数据上的key健
 */
export const arrKeyContacts = (data, keyArr) => {
  let _data = []
  if (Object.prototype.toString.call(keyArr) === '[object Array]') {
    keyArr.map((item) => {
      if (data[item] && data[item].length > 0) {
        _data = _data.concat(data[item])
      }
    })
  } else if (Object.prototype.toString.call(keyArr) === '[object String]') {
    if (data.keyArr && data.keyArr.length > 0) {
      _data = _data.concat(data.keyArr)
    }
  }
  return _data
}

/**
 *@Description: 将自定字符串里面的第一个/xx/、 /xx 去按你提高的过滤数组，匹配替换成空或者你指定替换的值
 *@param {String} replaceStr 被替换的字符串
 *@param {Array} filterArr 过滤的字符数组集合
 *@param {String} filterReplaceStr 需要将匹配值替换的字符串
 */
export const removeLanguagePrefix = (
  replaceStr: string,
  filterArr: string[],
  filterReplaceStr: string = ''
): string => {
  const regexPattern = /^\/([^\/?#]*)(\/.*)?$/
  return replaceStr.replace(regexPattern, (match, p1, p2) => {
    if (filterArr.includes(p1)) {
      const newPath = p2 || ''
      return newPath === '/' || newPath === '' ? filterReplaceStr : newPath // 如果结果是单独一个斜杠或空字符串，则返回空字符串
    }
    return match === '/' ? filterReplaceStr : match // 如果原始路径是单独一个斜杠，也返回空字符串
  })
}
