const supportLanguage = [
  'default',
  'en',
  'zh-cn',
  'zh-hk',
  'ja',
  'ko',
  'fi',
  'pl',
  'tr',
  'cs',
  'it',
  'de',
  'es',
  'fr',
  'ru',
  'pt',
  'nl',
  'uk',
  'hu',
  'sv',
  'kk',
  'el',
  'th',
  'bg',
  'sk',
  'lt',
  'ro',
  'no',
  'sq',
  'sl',
  'sr',
  'az',
  'da',
  'mk'
]
const extraSupportLanguage = ['id']
module.exports = {
  supportLanguage, // 支持语种
  extraSupportLanguage // 额外支持语种（目前只用在carriers详情页中）
}
