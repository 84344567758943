/* eslint-disable */
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const assetPrefix = publicRuntimeConfig.assetPrefix || ''

// 合作运输商列表
export const COOP_CARRIERS_LIST = [
  {
    key: '191360',
    _name: 'UOF INTERNATIONAL',
    '_name_zh-cn': '骏佳国际',
    '_name_zh-hk': '駿佳國際',
    _showUrl: 'www.uofexp.com/uof.aspx',
    _url: 'https://www.uofexp.com/uof.aspx',
    indexLogo: assetPrefix + '/assets/images/coop_carrier/191360_index.svg',
    logo: assetPrefix + '/assets/images/coop_carrier/191360_02.svg',
    _detail: `‘Let’s think more for you, do better’ is {UOF} service commitment onto our clients forever.\n
UOF International was founded in 2000, a certified 4A logistics enterprise and a certified AEO  customs clearance enterprise. UOF has an international express license issued by China Post , a strong network of over 20 wholly-owned branches throughout China and Japan. UOF International operates China-Japan logistics for over 20 years. UOF has become a leader in the cross-border e-commerce logistics market between China and Japan, and has created a independent logistics brand 'UOF'. UOF services provide the end-to-end logistics products for hundreds of thousands of Chinese and Japanese merchants, Chinese residents in Japan, and logistics partners. UOF products include Japan FBA transportation services, China platform semi-managed logistics services, Japan Sea Express, Japan LCL, Japan Air Express, Japan Air  Economic, Japan Air Parcel, Japan Bonded Warehousing, Japan customs clearance, FBA return/relabel service, FBA warehouse delivery, Japan Distribution and China warehousing services. It is praised by customers as the "China-Japan logistics expert".\n
UOF aims to deliver Economic Cost, Stable transit performance and Tailor-made logistics services for our clients. Wish to be your reliable logistics partner! `,
    '_detail_zh-cn': `“让我们为您想得更多，做得更好”是{骏佳}人面对广大客户一贯的服务宗旨。\n
骏佳国际以保障客户的物流成功为基础，提供标准化的中日双向全链路物流服务产品，让您的货件准时运、按时达, 确保客户订单履行快、准、稳、省! 
骏佳国际成立于2000 年，是国家4A级认证物流企业和国际海关组织AEO认证通关企业, 拥有国家邮政局颁发的国际快递经营许可证和遍及中日全境20多家独资自营的揽派服务网络。骏佳国际自营中日专线物流, 深耕中日跨境物流20多年，已成为中日电商跨境物流市场的领导者，打造了‘UOF’独立物流品牌。UOF为数十万家中日商家、在日华人和广大物流同行伙伴们提供了日本FBA头程服务、中国平台半托管服务、日本快船、日本普船、日本特快空派、日本空运经济、日本电商跨境小包、日本保税通关、日本海外仓一件代发、FBA退货/换标、FBA转运入库、日本配送、国内仓检品一件代发等中日双向、端到端全链路物流产品，被客户誉为“中日物流专家”。\n
UOF品牌服务，优质高效、经济可靠、专业快捷，您可靠的国际物流合作伙伴! `
  },
  // {
  //   key: '190271',
  //   _name: 'Cainiao',
  //   '_name_zh-cn': '菜鸟',
  //   '_name_zh-hk': '菜鸟',
  //   _showUrl: 'https://gpn.cainiao.com/home',
  //   _url: 'https://gpn.cainiao.com/home?channelCode=55ca946f43534a889da9e9382853fc7c',
  //   _detail:
  //     'Founded in 2013, {Cainiao} Group (“Cainiao”) is a smart logistics company and the logistics arm of Alibaba Group. As part of its commitment to create customer value, it adopts a collaborative approach to logistics that aims to improve efficiency and customer experience for all players along the supply chain. It carries forward Alibaba’s mission of making it easy to do business anywhere by aiming to deliver anywhere in China within 24 hours, and across the globe within 72 hours. For more information, please visit https://www.cainiao.com/en/index.html',
  //   '_detail_zh-cn':
  //     '{菜鸟国际快递}结合资源优势与行业积累，推出快线、标准等分层产品，为跨境平台商家、独立站、出海品牌及物流商提供全链路物流解决方案。例如英国专线方面，菜鸟国际快递推出了“快线”和“标准”的综合性跨境解决方案，英国快线可实现平均7个自然日妥投；英国标准，12天妥投率高达98.6%。',
  //   '_detail_zh-hk':
  //     '{菜鳥國際快遞}結合資源優勢與行業積累，推出快線、標準等分層產品，為跨境平臺商家、獨立站、出海品牌及物流商提供全鏈路物流解決方案。例如英國專線方面，菜鳥國際快遞推出了「快線」和「標準」的綜合性跨境解決方案，英國快線可實現平均7個自然日妥投；英國標準，12天妥投率高達98.6%。',
  //   _isExpand: false,
  //   _type: 'custom'
  // },
  // {
  //   key: '100296',
  //   _name: 'AJEX',
  //   '_name_zh-cn': 'AJEX',
  //   '_name_zh-hk': 'AJEX',
  //   _fullName: 'AJEX Logistics services',
  //   '_fullName_zh-cn': '埃捷国际物流',
  //   '_fullName_zh-hk': '埃捷國際物流',
  //   _url: 'http://www.aj-ex.com.cn',
  //   _detail:
  //     'AJEX International Logistics (Shenzhen) Co., Ltd is a joint venture between Ajlan & Bros Group and SF international, an expert in logistics and transportation in Saudi Arabia, providing Logistics Express Parcel and e-commerce solution. With the most advanced technology, we deliver, from China to the Middle-East, delightful and convenient experiences.AJEX International Logistics (Shenzhen) Co., Ltd introduces the latest technological advancements to the local express logistics sector through a strategic partnership with SF Express, logistic leader in China. With the coverage capability of providing logistics services in the Middle East and the technology and service network of SF Express, AJEX can cover most of the Chinese, Middle East and North African markets, and radiate to the global market.',
  //   '_detail_zh-cn':
  //     '埃捷国际物流（深圳）有限公司(简称AJEX) 由阿吉兰兄弟控股集团与顺丰国际合资创办，是沙特阿拉伯物流和运输行业专家，提供专业的物流快递包裹和跨境电商物流解决方案。凭借先进的技术，为您提供从中国到中东的快递物流，仓储派送和货到付款服务。作为一家业务范围将辐射全球的快递物流服务企业，AJEX已获得物流相关的运营资质，具备在沙特乃至中东地区提供物流服务的能力，目前的运营能力可覆盖陆运、空运、海运三大运输方式。',
  //   '_detail_zh-hk':
  //     '埃捷國際物流（深圳）有限公司（簡稱AJEX） 由阿吉蘭兄弟控股集團與順豐國際合資創辦，是沙烏地阿拉伯物流和運輸行業專家，提供專業的物流快遞包裹和跨境電商物流解決方案。 憑藉先進的技術，為您提供從中國到中東的快遞物流，倉儲派送和貨到付款服務。 作為一家業務範圍將輻射全球的快遞物流服務企業，AJEX已獲得物流相關的運營資質，具備在沙特乃至中東地區提供物流服務的能力，目前的運營能力可覆蓋陸運、空運、海運三大運輸方式。',
  //   _isExpand: false,
  //   _type: 'custom'
  // },
  //   {
  //       "key": "1000001",
  //       "_name": "Gouzoon",
  //       "_name_zh-cn": "Gouzoon",
  //       "_name_zh-hk": "Gouzoon",
  //       "_fullName": "Gouzoon全球物流集运",
  //       "_fullName_zh-cn": "Gouzoon全球物流集运",
  //       "_fullName_zh-hk": "Gouzoon全球物流集运",
  //       "_url": "https://www.gouzoon.com",
  //       "_detail": "Gouzoon全球物流集运服务平台，是通邮集团旗下的跨国集运服务业务，针对全球华人、留学生、电商消费者、中小电商企业的跨国物流运输需求，提供个人物品跨国集运、电商一件代发、电商代购等多种服务。平台拥有超过30条精品物流服务方案，可达到全球80多个国家，支持空运、海运、陆运多种运输方式。平台还在中国大陆、日本、中国台湾地区、中国香港、马来西亚、老挝、新加坡、加拿大等地设有集运仓，可支持海外仓仓内服务。",
  //       "_detail_zh-cn": "Gouzoon全球物流集运服务平台，是通邮集团旗下的跨国集运服务业务，针对全球华人、留学生、电商消费者、中小电商企业的跨国物流运输需求，提供个人物品跨国集运、电商一件代发、电商代购等多种服务。平台拥有超过30条精品物流服务方案，可达到全球80多个国家，支持空运、海运、陆运多种运输方式。平台还在中国大陆、日本、中国台湾地区、中国香港、马来西亚、老挝、新加坡、加拿大等地设有集运仓，可支持海外仓仓内服务。",
  //       "_detail_zh-hk": "Gouzoon全球物流集运服务平台，是通邮集团旗下的跨国集运服务业务，针对全球华人、留学生、电商消费者、中小电商企业的跨国物流运输需求，提供个人物品跨国集运、电商一件代发、电商代购等多种服务。平台拥有超过30条精品物流服务方案，可达到全球80多个国家，支持空运、海运、陆运多种运输方式。平台还在中国大陆、日本、中国台湾地区、中国香港、马来西亚、老挝、新加坡、加拿大等地设有集运仓，可支持海外仓仓内服务。",
  //       "_isExpand": true,
  //       "_type": "custom"
  //   },
  // {
  //   key: '1000002',
  //   _name: 'Updeals',
  //   '_name_zh-cn': 'Updeals',
  //   '_name_zh-hk': 'Updeals',
  //   _fullName: 'Updeals',
  //   _showUrl: 'https://www.updeals.com',
  //   _url: 'https://www.updeals.com',
  //   _detail:
  //     'Up your savings with Updeals. Updeals is a comprehensive money-saving platform designed to help users get access to various discounts and money-saving opportunities.<br/><br/>1. In collaboration with quality merchants, we offer exclusive deals unavailable elsewhere, with discounts up to 90% off, 90%+ positive reviews, and personally tried-and-tested picks!<br/>(https://www.updeals.com/editors-pick)<br/><br/>2. Get massive top discounts to find the best deals.<br/>(https://www.updeals.com/coupon/top-discount?type=topDiscount)<br/><br/>3. Learn about top brands popular discount information, and money-saving tips enter the blog.<br/>(https://www.updeals.com/blog)<br/><br/>4. Download APP points to redeem gifts and other features.<br/>(https://www.updeals.com/appPage/index.html)<br/><br/>5. Join the Updeals Discord community for sharing, giveaways, and direct conversations with the Updeals team.<br/>(https://discord.gg/pFBUYrMY)',
  //   '_detail_zh-cn':
  //     'Up your savings with Updeals. Updeals is a comprehensive money-saving platform designed to help users get access to various discounts and money-saving opportunities.<br/><br/>1. In collaboration with quality merchants, we offer exclusive deals unavailable elsewhere, with discounts up to 90% off, 90%+ positive reviews, and personally tried-and-tested picks!<br/>(https://www.updeals.com/editors-pick)<br/><br/>2. Get massive top discounts to find the best deals.<br/>(https://www.updeals.com/coupon/top-discount?type=topDiscount)<br/><br/>3. Learn about top brands popular discount information, and money-saving tips enter the blog.<br/>(https://www.updeals.com/blog)<br/><br/>4. Download APP points to redeem gifts and other features.<br/>(https://www.updeals.com/appPage/index.html)<br/><br/>5. Join the Updeals Discord community for sharing, giveaways, and direct conversations with the Updeals team.<br/>(https://discord.gg/pFBUYrMY)',
  //   '_detail_zh-hk':
  //     'Up your savings with Updeals. Updeals is a comprehensive money-saving platform designed to help users get access to various discounts and money-saving opportunities.<br/><br/>1. In collaboration with quality merchants, we offer exclusive deals unavailable elsewhere, with discounts up to 90% off, 90%+ positive reviews, and personally tried-and-tested picks!<br/>(https://www.updeals.com/editors-pick)<br/><br/>2. Get massive top discounts to find the best deals.<br/>(https://www.updeals.com/coupon/top-discount?type=topDiscount)<br/><br/>3. Learn about top brands popular discount information, and money-saving tips enter the blog.<br/>(https://www.updeals.com/blog)<br/><br/>4. Download APP points to redeem gifts and other features.<br/>(https://www.updeals.com/appPage/index.html)<br/><br/>5. Join the Updeals Discord community for sharing, giveaways, and direct conversations with the Updeals team.<br/>(https://discord.gg/pFBUYrMY)',
  //   _isExpand: true,
  //   _type: 'custom',
  //   hideImgBg: true
  // },
  {
    key: '1000003',
    _name: 'Reship',
    '_name_zh-cn': 'Reship',
    '_name_zh-hk': 'Reship',
    _fullName: 'Reship',
    _showUrl: 'https://www.reship.com',
    _url: 'https://www.reship.com/?utm_source=17track&utm_medium=affiliate&utm_campaign=crossborder&utm_id=17track&utm_content=overview',
    _detail:
      '{Reship} is an international package forwarding service that facilitates shopping from US, UK, Canadian, and international retailers such as Nike, Target, Walmart, Amazon, eBay & more! When you sign up for a Reship account you will receive a dedicated virtual suite in the US (tax-free), United Kingdom, and Canada, and have access to a range of value-added services, including consolidation and returns. Shop the world with Reship.',
    '_detail_zh-cn':
      '{Reship} is an international package forwarding service that facilitates shopping from US, UK, Canadian, and international retailers such as Nike, Target, Walmart, Amazon, eBay & more! When you sign up for a Reship account you will receive a dedicated virtual suite in the US (tax-free), United Kingdom, and Canada, and have access to a range of value-added services, including consolidation and returns. Shop the world with Reship.',
    '_detail_zh-hk':
      '{Reship} is an international package forwarding service that facilitates shopping from US, UK, Canadian, and international retailers such as Nike, Target, Walmart, Amazon, eBay & more! When you sign up for a Reship account you will receive a dedicated virtual suite in the US (tax-free), United Kingdom, and Canada, and have access to a range of value-added services, including consolidation and returns. Shop the world with Reship.',
    _isExpand: true,
    _type: 'custom'
  },
  //   {
  //     "key": "1000004",
  //     "_name": "Austway express",
  //     "_name_zh-cn": "深圳澳华国际物流",
  //     "_name_zh-hk": "深圳澳華國際物流",
  //     "_url": "https://www.austwayexpress.com/",
  //     "_detail": "Austway express is a one-stop China-Australian logistics provider.<br/>Its main business includes air freight, sea freight, small parcels,FBA,warehouses, clearance and delivery etc., we can solves the China-Australian logistics transportation problems for our customers through multiple channels and solutions.",
  //     "_detail_zh-cn": "深圳澳华国际物流是一家一站式中澳物流提供商，主营业务包括空运，海运， 小包，FBA， 海外仓，清关和派送等，通过多渠道多方案为我们的客人解决中澳物流运输问题。",
  //     "_detail_zh-hk": "深圳澳華國際物流是一家一站式中澳物流提供商，主營業務包括空運，海運， 小包，FBA， 海外倉，清關和派送等，通過多渠道多方案為我們的客人解決中澳物流運輸問題。",
  //     "_isExpand": true,
  //     "_type": "custom"
  //   },
  {
    key: '1000005',
    _name: 'AYS Logistics',
    '_name_zh-cn': 'AYS Logistics',
    '_name_zh-hk': 'AYS Logistics',
    _showUrl: 'https://www.needsend.com',
    _url: 'https://www.needsend.com/',
    _detail:
      'Shenzhen Ang Yi Shun Taida International Logistics Co., Ltd. specializes in international express, dedicated lines, air freight, sea freight, customs declaration, trucking, warehousing, and logistics consulting. With advanced management, efficient resource allocation, and continuous innovation, we offer a comprehensive global logistics and freight forwarding service network.',
    '_detail_zh-cn':
      '深圳昂易顺泰达国际物流有限公司专业从事国际快递、国际专线、国际空运、国际海运及报关报检、拖车、仓储、物流咨询及物流方案等国际货运代理业务的一家综合性的国际物流企业。凭借完善的运营系统、科学的资源配置、先进的管理技术，并经过不断的创新和发展，形成了覆盖全球的国际物流货运代理服务网络体系。',
    '_detail_zh-hk':
      '深圳昂易順泰達國際物流有限公司專業從事國際快遞、國際專線、國際空運、國際海運及報關報檢、拖車、倉儲、物流咨詢及物流方案等國際貨運代理業務的壹家綜合性的國際物流企業。憑借完善的運營系統、科學的資源配置、先進的管理技術，並經過不斷的創新和發展，形成了覆蓋全球的國際物流貨運代理服務網絡體系。',
    // 是否不展示查询框
    _no_tracking: true,
    _type: 'custom'
  },
  {
    key: '190172',
    _expressGroup: '10',
    _iconBgColor: '56B70E',
    _isOur: 'false',
    _order: '1136',
    _rule: 'LTE[A-Z]{2}\\d{10}YQ',
    _ruleDesc: 'LT *** *** *** **,LTE## *** *** *** * YQ',
    _ruleHash: '-2037082256,-1590238199',
    _url: 'http://www.ltexp.com.cn/',
    _name: 'Letian Express',
    '_name_zh-cn': '乐天速递',
    '_name_zh-hk': '樂天速遞',
    _type: 'default',
    _detail:
      'Shanghai Letian Express Co., Ltd. was established in 1999, focusing on providing cross-border logistics solutions for the North American market. The company is headquartered in Shanghai and has branches and warehouses in Shenzhen, Yiwu, the United States and Canada. The overseas warehouse covers an area of 100000 square meters and has complete logistics operation equipment, including warehousing equipment, lifting equipment, material handling equipment, and other logistics peripheral equipment. Since its establishment, our company has been engaged in e-commerce parcel business, covering small packages for Canada and the United States, self operated overseas warehouses, and freight forwarding.',
    '_detail_zh-cn':
      '上海乐天速递有限公司于1999年成立，专注于提供北美市场跨境物流解决方案。公司总部位于上海，在国内深圳、义乌及美国加拿大等地设有分公司和仓库。海外仓库面积达10万平方米，具有完善的物流运营设备，包括仓储设备、起重器械、物料搬运设备以及其它物流周边设备等。从成立至今我司一直从事电商包裹类业务，业务范围涵盖加拿大美国专线小包、自营海外仓及集货转运等。',
    '_detail_zh-hk':
      '上海樂天速遞有限公司於1999年成立，專註於提供北美市場跨境物流解決方案。公司總部位於上海，在國內深圳、義烏及美國加拿大等地設有分公司和倉庫。海外倉庫面積達10萬平方米，具有完善的物流運營設備，包括倉儲設備、起重器械、物料搬運設備以及其它物流周邊設備等。從成立至今我司一直從事電商包裹類業務，業務範圍涵蓋加拿大美國專線小包、自營海外倉及集貨轉運等。'
  },
  {
    key: '190008',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'https://www.yunexpress.cn/',
    _name: 'YunExpress',
    '_name_zh-cn': '云途物流',
    '_name_zh-hk': '雲途物流',
    _type: 'default',
    _detail:
      'Founded in Shenzhen in 2014, {YunExpress} focuses on e-commerce parcels and provides professional direct delivery service of global parcels for cross-border e-commerce enterprises in China.',
    '_detail_zh-cn':
      '{云途物流}（YunExpress）成立于2014年，总部位于深圳，聚焦电商件，为中国跨境电商企业提供专业的全球小包裹直发服务。目前云途物流在全球拥有1500余名专业的物流服务员工，设有15+集货中转仓，在中国大陆地区设有20+分公司，日均包裹订单量达70余万件，服务范围覆盖全球220+国家和地区，是Amazon、Wish、JOOM、VOVA、Shopify等国际主流电商平台推荐的物流服务商。未来，云途物流将继续秉持不忘初心的态度，以持续技术创新为客户不断创造价值，帮助更多中国跨境电商企业走向世界。',
    '_detail_zh-hk':
      '{雲途物流}（YunExpress）成立於2014年，總部位於深圳，聚焦電商件，為中國跨境電商企業提供專業的全球小包裹直發服務。目前云途物流在全球擁有1500餘名專業的物流服務員工，設有15+集貨中轉倉，在中國大陸地區設有20+分公司，日均包裹訂單量達70餘萬件，服務範圍覆蓋全球220+國家和地區，是Amazon、Wish、JOOM、VOVA、Shopify等國際主流電商平台推薦的物流服務商。未來，雲途物流將繼續秉持不忘初心的態度，以持續技術創新為客戶不斷創造價值，幫助更多中國跨境電商企業走向世界。'
  },
  {
    key: '190002',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.flytexpress.com/',
    _name: 'FLYT',
    '_name_zh-cn': '飞特物流',
    '_name_zh-hk': '飛特物流',
    _type: 'default',
    _detail:
      '{Flyt} Logistics Co., Ltd. is a leading service provider engaged in international air mail, international air express and international storage and distribution, also one of the first partners and recommended logistics service providers of China EBAY. The company provides various post, storage and distribution, order management system, for multinational electronic commerce operators.',
    '_detail_zh-cn':
      '{飞特物流}(flytexpress)是中国实力雄厚的国际物流综合服务企业。公司成立于2008年，总部位于广州，主要为跨境电子商务运营商提供多方位的跨境物流服务。现已在深圳、义乌、上海、杭州、许昌、郑州等多地拥有直属分公司。服务网络遍布美国、英国、法国、德国、意大利、加拿大、墨西哥、俄罗斯等上百个国家。经过13年的发展，飞特物流目前已经拥有了比较完善的运营体系，拥有员工500+，自建全球分拣操作场所总面积超过50000平方米，日均可处理订单70万票。',
    '_detail_zh-hk':
      '{飛特物流}(flytexpress)是中國實力雄厚的國際物流綜合服務企業。公司成立於2008年，總部位於廣州，主要為跨境電子商務運營商提供多方位的跨境物流服務。現已在深圳、義烏、上海、杭州、許昌、鄭州等多地擁有直屬分公司。服務網絡遍布美國、英國、法國、德國、意大利、加拿大、墨西哥、俄羅斯等上百個國家。經過13年的發展，飛特物流目前已經擁有了比較完善的運營體系，擁有員工500+，自建全球分揀操作場所總面積超過50000平方米，日均可處理訂單70萬票。'
  },
  {
    key: '190120',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.jcex.com/',
    _name: 'JCEX',
    '_name_zh-cn': '佳成国际',
    '_name_zh-hk': '佳成國際',
    _type: 'default',
    _detail:
      '{JCEX} International Logistics，Founded in 2000, has been engaged in door-to-door international logistics business. Since 2013, JCEX has focused on cross-border e-commerce logistics. In July 2021, JCEX was controlled by &quot;China Logistics Group Co., LTD.&quot;, a listed enterprise. The company has more than 50 branches at home and abroad, and the logistics service area covers more than 200 countries and regions. It is a comprehensive international logistics company with full license, Amazon SPN logistics service provider, Jingdong international strategic partner, Alibaba&rsquo;s international station and Cainiao network, Dunhuang network and other designated international logistics supplier. It can ship by air, sea, rail, land, express and other logistics methods, mainly in Europe, the United States, Japan, the Middle East and other regions of FBA, spontaneous goods, etc.',
    '_detail_zh-cn':
      '{JCEX佳成国际物流}成立于2000年，一直从事门到门的国际物流业务，13年起专注于跨境电商物流，2021年7月被“中国物流集团有限公司”下属上市企业“华贸物流”控股。 公司旗下有境内外逾50家分支机构，物流服务区域覆盖200多个国家和地区，是综合型全牌照国际物流公司，亚马逊SPN物流服务商，京东国际战略合作伙伴，阿里巴巴旗下国际站与菜鸟网络、敦煌网等多家平台的指定国际物流供应商。 可发空运、海运、铁运、陆运、快递等多种物流方式，主营欧、美、日、中东等地区FBA、自发货等。',
    '_detail_zh-hk':
      '{JCEX佳成國際物流}成立於2000年，一直從事門到門的國際物流業務，13年起專注於跨境電商物流，2021年7月被“中國物流集團有限公司”下屬上市企業“華貿物流”控股。 公司旗下有境內外逾50家分支機構，物流服務區域覆蓋200多個國家和地區，是綜合型全牌照國際物流公司，亞馬遜SPN物流服務商，京東國際戰略合作夥伴，阿里巴巴旗下國際站與菜鳥網絡、敦煌網等多家平台的指定國際物流供應商。 可發空運、海運、鐵運、陸運、快遞等多種物流方式，主營歐、美、日、中東等地區FBA、自發貨等。'
  },
  {
    key: '190208',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.cne.com/',
    _name: 'CNE',
    '_name_zh-cn': 'CNE',
    '_name_zh-hk': 'CNE',
    _type: 'default',
    _detail:
      '{CNE International Express} Co., Ltd., engaged in international logistics, was founded in 2002 . CNE is based on intelligent network platform and automatic sorting machine and individually become a professional international logistic company.We are the trusted partner of Wish , AliExpress , DHgate ，Joom and Vova platform.',
    '_detail_zh-cn':
      '{CNE}自2002年始专注于国际物流服务，现已成为专业的国际物流公司。依托智能化的网络平台，结合自动分拣机的高效操作，CNE实现了包裹转运流程的现代化、信息化和专业化。CNE业务遍及美国、英国、法国和德国等全球231国家和地区，同时也是Wish、AliExpress、DHgate，Joom和Vova等跨境电商平台的官方合作伙伴。',
    '_detail_zh-hk':
      '{CNE}自2002年始專注於國際物流服務，現已成為專業的國際物流公司。依托智能化的網絡平台，結合自動分揀機的高效操作，CNE實現了包裹轉運流程的現代化、信息化和專業化。 CNE業務遍及美國、英國、法國和德國等全球231國家和地區，同時也是Wish、AliExpress、DHgate，Joom和Vova等跨境電商平台的官方合作夥伴。'
  },
  {
    key: '190011',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.1001000.com/',
    _name: 'BQC',
    '_name_zh-cn': '百千诚物流',
    '_name_zh-hk': '百千誠物流',
    _type: 'default',
    _detail:
      '{BQC} International Logistics has the qualification of customs declaration and inspection, road transportation, express operation license and other related operations approved by the state administrative organs in the logistics industry. The company&rsquo;s logistics products are diversified into four parts: international express, international air transportation, international special line and postal parcel.',
    '_detail_zh-cn':
      '{百千诚国际物流}在物流产业方面拥有获得国家行政机关批准的报关报检、道路运输、快递经营许可等相关运营资质；公司物流产品多元化，分为国际快递、国际空运、国际专线、邮政小包四大版块，同时为香港DHL签约代理商、香港DHL eCommerce代理商、香港国泰航空（CX）、阿联酋航空（EK）、菲律宾航空（PR）的紧密合作伙伴。',
    '_detail_zh-hk':
      '{百千誠國際物流}在物流產業方面擁有獲得國家行政機關批准的報關報檢、道路運輸、快遞經營許可等相關運營資質；公司物流產品多元化，分為國際快遞、國際空運、國際專線、郵政小包四大版塊，同時為香港DHL簽約代理商、香港DHL eCommerce代理商、香港國泰航空（CX）、阿聯酋航空（EK）、菲律賓航空（PR）的緊密合作夥伴。'
  },
  {
    key: '190200',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.ydhex.com/',
    _name: 'YDH',
    '_name_zh-cn': 'YDH',
    '_name_zh-hk': 'YDH',
    _type: 'default',
    _detail:
      '{YDH} Cross border was established in 2002 and the headquarters locates in Shanghai, China. YDH has become a comprehensive cross-border logistics operator integrating air and sea transportation, express delivery and overseas warehousing service. Its service network covers major countries in Asia, Europe, America, Oceania and other regions , providing customers with professional international transportation solutions and cross-border logistics services.<br/><br/>Set sail just for delivery. Adhering to "safe, fast, accurate and professional" service tenet, YDH is committed to satisfy customer requirements for high-quality cross-border logistics. ',
    '_detail_zh-cn':
      '{义达跨境}（YDH）初创于2002年，总部位于上海。经过二十余年发展，义达已成为集跨境物流、海外仓储、保税报关等为一体的跨境物流综合运营商，业务范围覆盖B2B、B2C、C2C等业务模式。服务网络覆盖国内主要跨境电商城市和亚洲、欧洲、美洲、大洋洲等地区国家，为广大客户提供一站式多元化国际运输解决方案和跨境物流服务。<br/><br/>扬帆出海，只为送达。义达秉承“安全、快捷、准确、专业”的服务宗旨，在“义不容辞，使命必达”的精神指引下，致力于满足客户对高品质跨境物流的需求，努力打造YDH快递品牌，成为丝路电商及数字贸易基础设施完备的综合物流服务商，在买全球、卖全球领域协助我们的客户把握商机做大做强！',
    '_detail_zh-hk':
      '{義達跨境}（YDH）初創於2002年，總部位於上海。經過二十余年發展，義達已成為集跨境物流、海外倉儲、保稅報關等為壹體的跨境物流綜合運營商，業務範圍覆蓋B2B、B2C、C2C等業務模式。服務網絡覆蓋國內主要跨境電商城市和亞洲、歐洲、美洲、大洋洲等地區國家，為廣大客戶提供壹站式多元化國際運輸解決方案和跨境物流服務。<br/><br/>揚帆出海，只為送達。義達秉承“安全、快捷、準確、專業”的服務宗旨，在“義不容辭，使命必達”的精神指引下，致力於滿足客戶對高品質跨境物流的需求，努力打造YDH快遞品牌，成為絲路電商及數字貿易基礎設施完備的綜合物流服務商，在買全球、賣全球領域協助我們的客戶把握商機做大做強！'
  },
  {
    key: '100011',
    _expressGroup: '11',
    _isOur: 'true',
    _url: 'http://www.oneworldexpress.com/',
    _name: 'One World',
    '_name_zh-cn': 'One World',
    '_name_zh-hk': 'One World',
    _type: 'default',
    _detail:
      '{One World} Express is a global E-commerce logistics solution supply company which established in 1998. We provide multiple solutions for global E-commerce market which are integrated to our multi-courier shipping platform. Our global customer service team will bring the most satisfaction to our regional partners. Your package is our pride!',
    '_detail_zh-cn':
      '{One World} Express创立于1998年，是一家专注于提供电商物流解决方案的渠道供应商，One World与全球邮政、四大速递等知名企业有着超过10年以上的合作关系并且多年为邮政联盟的顾问公司；One World有自己的物流管理操作平台以及海外仓储配送体系，希望与亚洲物流合作伙伴一起创造更多优质的跨境物流解决方案！',
    '_detail_zh-hk':
      '{TNT快遞}是全球最大的快遞公司之一，業務覆蓋全球200個國家，並在歐洲佔有穩固的地位。我們確保您的包裹、文件和貨物安全準時抵達世界各地。您能夠享受廣泛的服務，包括為汽車、工業、醫療保健、高科技行業以及中小型企業定制的強大解決方案組合。我們的歐洲公路網絡無與倫比，連接40多個歐洲國家，擁有19個公路樞紐和550個站點，並與我們的國際空運網絡無縫對接！'
  },
  {
    key: '190086',
    _expressGroup: '12',
    _isOur: 'false',
    _order: '1062',
    _url: 'http://www.wanbexpress.com/',
    _name: 'Wanb Express',
    '_name_zh-cn': 'Wanb Express',
    '_name_zh-hk': 'Wanb Express',
    _type: 'default',
    _detail:
      '{Wanbexpress} is a professional cross-border logistics service provider, mainly provides customized services according to the needs of cross-border online sellers, service covers mainly in Europe and North America etc., which is more than 200 countries, including domestic express or mail services, also providing four big international couriers services.',
    '_detail_zh-cn':
      '{万邦速达}(WanB Express)，专注出口跨境电商的物流，我们于华南、华东、香港地区、英国等地皆设有办事处或操作中心。至今，作为亚马逊SPN物流服务商，我们已对接上25+电商平台或独立站后台，致力以欧美专线小包、FBA及其他仓头程、英国海外仓等为全球卖家带来门到门的物流服务。立即联系17Track专属客户经理Bill微信18938891152。',
    '_detail_zh-hk':
      '{萬邦速達}(WanB Express)，專註出口跨境電商的物流，我們於華南、華東、香港地區、英國等地皆設有辦事處或操作中心。至今，作為亞馬遜SPN物流服務商，我們已對接上25+電商平臺或獨立站後臺，致力以歐美專線小包、FBA及其他倉頭程、英國海外倉等為全球賣家帶來門到門的物流服務。立即聯系17Track專屬客戶經理Bill微信18938891152。'
  },
  {
    key: '190018',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.buylogic.cc/',
    _name: 'BuyLogic',
    '_name_zh-cn': '捷买送',
    '_name_zh-hk': '捷買送',
    _type: 'default',
    _detail:
      '{BuyLogic} was set-up in 2011 in Shenzhen with a vision to provide a global one-stop warehousing & distribution solution in the B2C ecommerce market, with branches in Guangzhou, Shanghai & Yiwu.',
    '_detail_zh-cn':
      '{捷买送}成立于2011年，致力于服务全球电商，为全球电商提供邮政小包、专线小包，仓储分拣等一站式无缝服务，是一家集资源、人才、网络于一体的大型B2C物流服务平台。总部位于广东深圳，在广州、上海、义乌均设有分公司，全部操作面积超过7000平方，日订单处理能力近40万件。我们有20多辆车在这四个城市每天提供上门取件服务。',
    '_detail_zh-hk':
      '{捷買送}成立於2011年，致力於服務全球電商，為全球電商提供郵政小包、專線小包，倉儲分揀等一站式無縫服務，是一家集資源、人才、網絡於一體的大型B2C物流服務平台。總部位於廣東深圳，在廣州、上海、義烏均設有分公司，全部操作面積超過7000平方，日訂單處理能力近40萬件。我們有20多輛車在這四個城市每天提供上門取件服務。'
  },
  {
    key: '190074',
    _url: 'https://www.szty56.com/',
    _name: 'TopYou',
    '_name_zh-cn': '通邮集团',
    '_name_zh-hk': '通郵集團',
    _type: 'default',
    _detail:
      'Shenzhen {Tongyou} Logistics Technology Group Co., Ltd, founded in 2016, is an integrated cross-border e-commerce logistics provider. And it is headquartered in Shenzhen, China.The company&rsquo;s business scope covers postal parcels, international special lines, international consolidation and FBA headway to Japan, the US, Europe and the UK. The company has four major consolidation and transit warehouses in Shenzhen, Guangzhou, Shanghai and Yiwu, and has branches and molecular companies in Quanzhou, Hangzhou, Changsha, Singapore, Hong Kong and Taiwan, all of which have independent operation teams and they specialise in collection to serve customers in the region. The company has built up a professional IT team to serve customers with its self-developed Tongpost Intelligent Logistics System and intelligent logistics operation equipment.The company&rsquo;s warehouse has 10,000 square metres of operating space, including intelligent inbound equipment and small, medium and large sorting equipment. It has a daily parcel handling capacity of 1 million + tickets.The company has its own port truck, regular way of customs clearance, year-round package warehouse and board, and stable price position. These advantages are well received by customers of major platforms.Since the establishment of the channel to the main export trade of the color cosmetics industry, cosmetics industry, 3c with electricity products as the main source of development of goods, the company has always been committed to cross-border e-commerce platform and e-commerce sellers tailored to create intelligent logistics solutions.',
    '_detail_zh-cn':
      '{通邮物流}，成立于2016年，是一家跨境电商物流综合服务商，总部位于中国深圳。业务范围涵盖邮政小包、国际专线、国际集运及日本、美国、欧洲、英国FBA头程等。公司设有深圳、广州、上海、义乌四大集货中转仓，并在泉州、杭州、长沙、新加坡、香港及台湾等地设有分部及分子公司，均有独立运营团队，专业化揽收服务于区域内的客户。公司打造专业IT团队，凭借自主研发的通邮智慧物流系统及智能物流操作设备，来服务于客户。公司拥有10000平方米的操作场地，拥有智能入库设备及小型、中型、大型分拣设备，日处理包裹能力达100万+票。公司拥有自主港车，正规方式通关，常年包仓包板，且价格仓位稳定，得到各大平台客户的一致好评。渠道自建立以来，以主打出口贸易的彩妆行业，化妆品行业，3c带电产品作为主打发展货物来源，公司始终致力于为跨境电商平台及电商卖家量身打造智能物流解决方案。',
    '_detail_zh-hk':
      '{通郵物流}，成立於2016年，是一家跨境電商物流綜合服務商，總部位於中國深圳。業務範圍涵蓋郵政小包、國際專線、國際集運及日本、美國、歐洲、英國FBA頭程等。公司設有深圳、廣州、上海、義烏四大集貨中轉倉，並在泉州、杭州、長沙、新加坡、香港及臺灣等地設有分部及分子公司，均有獨立運營團隊，專業化攬收服務於區域內的客戶。公司打造專業IT團隊，憑借自主研發的通郵智慧物流系統及智能物流操作設備，來服務於客戶。公司擁有10000平方米的操作場地，擁有智能入庫設備及小型、中型、大型分揀設備，日處理包裹能力達100萬+票。公司擁有自主港車，正規方式通關，常年包倉包板，且價格倉位穩定，得到各大平臺客戶的一致好評。渠道自建立以來，以主打出口貿易的彩妝行業，化妝品行業，3c帶電產品作為主打發展貨物來源，公司始終致力於為跨境電商平臺及電商賣家量身打造智能物流解決方案。'
  },
  {
    key: '190072',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.sypost.com/',
    _name: 'SUNYOU',
    '_name_zh-cn': '顺友物流',
    '_name_zh-hk': '順友物流',
    _type: 'default',
    _detail:
      'Founded in 2008, {Shenzhen Sunyou} Logistics Co.,Ltd provides a range of services ranging from Shunyoubao special lines, Sunyoutong small packets Global express delivery & warehousing etc. It has over 30,000 square meters of package-handling and storage centers in each transfer center and process hundreds of thousands of international parcels per day.',
    '_detail_zh-cn':
      '{深圳市顺友物流}有限公司成立于2008年，业务范围包括顺邮宝专线、顺友通小包、顺速宝专线、全球速递以及仓储订单处理服务，在深圳、义乌、广州、香港、洛杉矶、法兰克福、吉隆坡等地拥有超过30000平米的包裹处理及仓储中心，日均处理国际包裹达数十万件，服务超过10000家跨境电商企业，累计送达包裹超过10亿件，讲诚信、重服务是顺友不变的态度。',
    '_detail_zh-hk':
      '{深圳市順友物流}有限公司成立於2008年，業務範圍包括順郵寶專線、順友通小包、順速寶專線、全球速遞以及倉儲訂單處理服務，在深圳、義烏、廣州、香港、洛杉磯、法蘭克福、吉隆坡等地擁有超過30000平米的包裹處理及倉儲中心，日均處理國際包裹達數十萬件，服務超過10000家跨境電商企業，累計送達包裹超過10億件，講誠信、重服務是順友不變的態度。'
  },
  {
    key: '190085',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.shwise.cn/',
    _name: 'Wise Express',
    '_name_zh-cn': '万色速递',
    '_name_zh-hk': '萬色速遞',
    _type: 'default',
    _detail:
      'Shanghai {Wise Express} was set up in 2002, is a professional team to provide international logistics services. Wise rely on independent research and development of the logistics management system for businesses to provide the best logistics solutions and services.',
    '_detail_zh-cn':
      '上海{万色速递}有限公司成立于2002年，是为中国跨境电商用户提供配套国际物流服务的专业团队。万色以严谨、有序、专业、高效的运营标准努力完成客户与物流渠道之间的无缝链接，为客户提供合理的物流解决方案。万色不断提升、优化运营标准及流程，以客户和平台的体验度为目标。依托自行研发的物流管理系统准确、高效地为客户提供专业物流服务。',
    '_detail_zh-hk':
      '上海{萬色速遞}有限公司成立於2002年，是為中國跨境電商用戶提供配套國際物流服務的專業團隊。萬色以嚴謹、有序、專業、高效的運營標準努力完成客戶與物流渠道之間的無縫鏈接，為客戶提供合理的物流解決方案。萬色不斷提升、優化運營標準及流程，以客戶和平台的體驗度為目標。依托自行研發的物流管理系統準確、高效地為客戶提供專業物流服務。'
  },
  {
    key: '190194',
    _expressGroup: '12',
    _isOur: 'true',
    _url: 'http://www.tarrive.com',
    _name: 'TONDA GLOBAL',
    '_name_zh-cn': '通达全球',
    '_name_zh-hk': '通達全球',
    _type: 'default',
    _detail:
      '{TONDA GLOBAL} was founded in 2016 and is a comprehensive international logistics enterprise specializing in cross-border logistics services. Headquartered in Qingdao, Shandong, with 10+branches in Weihai, Yiwu, Jinhua, Guangzhou, Shenzhen, Dongguan etc., and 2 wholly-owned subsidiaries in Korea, Tonda Global has three major operation centers in South China, North China, and East China, as well as 10+domestic warehouses with an area of over 30000 square meters. The company has self-built overseas warehouses with over20000 square meters and is the official designated logistics service provider for top e-commerce platforms such as Amazon、Wish、Temu、Tiktok、Shein、Aliexpress、CoupangAmazon, Coupang, Temu, and Tiktok. Tonda Global has its own self-developed cross-border logistics service system that can process an average order volume of over 1 million daily. It can provide logistics solutions such as cross-border dedicated small parcels, overseas warehouses, FBA first leg, sea and air freight, and door-to-door logistics services for cross-border platform merchants, independent stations, logistics companies, etc. to help more Chinese sellers go global, please contact our 17Track customer service manager:Zhou WeChat:Z13356895630',
    '_detail_zh-cn':
      '{通达全球}（TONDA GLOBAL）于2016年创立，是一家专注于跨境物流服务的规模型国际物流企业。总部位于山东青岛，分别在威海、义乌、金华、广州、深圳、东莞等地设有分公司10+，在韩国开设2家全资子公司，在中国设有华南、华北、华东三大运营板块，国内集货仓10+，面积达30000㎡+，自建海外仓面积超过20000平方米，是Amazon、Wish、Temu、Tiktok、Shein、Aliexpress、Coupang等头部电商平台官方物流服务商，通达全球拥有自主研发的跨境物流综合服务系统，日均单量可处理 100万+，可为跨境平台商家、独立站、物流商等提供跨境专线小包、海外仓、FBA头程、海运空运、门到门全链路的物流解决方案，帮助更多中国卖家出海，请联系我们17Track专属客户经理:Zhou微信Z13356895630',
    '_detail_zh-hk': ''
  },
  // {
  //   key: '190047',
  //   _expressGroup: '12',
  //   _isOur: 'true',
  //   _url: 'http://www.szanjun.com/',
  //   _name: 'Anjun',
  //   '_name_zh-cn': '安骏物流',
  //   '_name_zh-hk': '安駿物流',
  //   _type: 'default',
  //   _detail:
  //     '{Anjun} logistics was founded in Shenzhen at 2011 which was the earliest international logistics focusing on B2C Cross-border electricity supplier service.',
  //   '_detail_zh-cn':
  //     '{安骏物流}2011年创立于深圳，是深圳早批专注B2C跨境电商的国际物流服务商，凭借创业团队十多年的从业经历及诚实守信的经营作风，以灵活务实的创业拼劲，快速适应并满足变化中的电商物流需求，在竞争激烈的电商物流行业获得新的突破及高于同行的成长速度。',
  //   '_detail_zh-hk':
  //     '{安駿物流}2011年創立於深圳，是深圳早批專注B2C跨境電商的國際物流服務商，憑藉創業團隊十多年的從業經歷及誠實守信的經營作風，以靈活務實的創業拼勁，快速適應並滿足變化中的電商物流需求，在競爭激烈的電商物流行業獲得新的突破及高於同行的成長速度。'
  // },
  //   {
  //       "key": "190376",
  //       "_expressGroup": "10",
  //       "_isOur": "false",
  //       "_url": "http://www.666post.com/",
  //       "_name": "E D POST",
  //       "_name_zh-cn": "E D POST",
  //       "_name_zh-hk": "E D POST",
  //       "_type": "default",
  //       "_detail": "Yi De International was founded in 2008. Focus on international e-commerce platform services: Ebay, Wish, Amazon, Alibaba, Express and other well-known national companies with their own platform. Main business includes: international postal parcels, international special lines, international air and sea transportation.",
  //       "_detail_zh-cn": "易徳国际成立于2008年。专注国际电商平台服务：Ebay、Wish、亚马逊、阿里巴巴、速卖通等自有平台全国知名公司。主营业务包括：国际邮政小包裹、国际专线、国际空海运。",
  //       "_detail_zh-hk": "易徳國際成立於2008年。專注國際電商平台服務：Ebay、Wish、亞馬遜、阿里巴巴、速賣通等自有平台全國知名公司。主營業務包括：國際郵政小包裹、國際專線、國際空海運。"
  //   },
  // {
  //   key: '190644',
  //   _name: 'JUSDA SR',
  //   '_name_zh-cn': '准时达跨境',
  //   '_name_zh-hk': '準時達跨境',
  //   _showUrl: 'https://www.jusdasr.com/',
  //   _url: 'https://www.jusdasr.com/',
  //   _detail:
  //     'JUSDA is the only authorized supply chain management platform service company of Foxconn Technology Group and has accumulated nearly 20 years of experience in lean supply chain management, focusing on end-to-end supply chain integration in the whole process for manufacturing industry ,upstream from raw material to finished product and downstream from the finished product to the terminal consumer.<br/>JUSDASR focuses on energize supply chain on the field of new retail cross-border, and provides tailor-made supply chain solutions for Merchants and Manufacturers.As of now,JUSDASR&rsquo;s services include global e-commerce parcels delevery,international shipping services, global warehouse and fulfillment.',
  //   '_detail_zh-cn':
  //     '富士康科技集团”授权的供应链物流科技管理平台服务公司，积累了近20年的精益供应链管理经验，专注制造业上游从原材料到成品，以及下游从成品到终端消费者的全程端到端供应链整合。<br/>准时达跨境，专注赋能跨境新零售供应链，为M（Merchant 商家、Manufacturer 制造商）提供定制化供应链解决方案，目前提供专业的直发专线、头程物流、海外仓配等物流服务。<br/>有需要可直接添加客服微信：Aaron192363',
  //   '_detail_zh-hk':
  //     '準時達，「富士康科技集團」授權的供應鏈物流科技管理平臺服務公司，積累了近20年的精益供應鏈管理經驗，專註製造業上遊從原材料到成品，以及下遊從成品到終端消費者的全程端到端供應鏈整合。<br/>準時達跨境，專註賦能跨境新零售供應鏈，為M（Merchant 商家、Manufacturer 製造商）提供定製化供應鏈解決方案，目前提供專業的直發專線、頭程物流、海外倉配等物流服務。<br/>有需要可直接添加客服微信：Aaron192363'
  // },
  {
    key: '190199',
    _name: 'JS Express',
    '_name_zh-cn': '急速国际',
    '_name_zh-hk': '急速國際',
    _showUrl: 'http://www.js-exp.com',
    _url: 'http://www.js-exp.com',
    _detail:
      '{JS Express} was registered in Hong Kong, China in 2010 and set up its operation headquarters in Suzhou in 2011, which is a logistics service company focusing on cross-border e-commerce field. Since its establishment, JS Express has 20+ branches in China, overseas warehouses in major countries and regions in North America and Europe, and close cooperation with major platforms such as Amazon, Wal-Mart, Shopify, Wish, Aliexpress, eBay , Shopee and Ali International. At the same time, Rush has reached strategic cooperation with more than 30 famous airlines such as China Eastern Airlines, China Southern Airlines and American Airlines, and has core resources such as Matson, COSCO, China Railway and postal services of various countries. With technological innovation as the core driving force, Rush has grown into one of the influential enterprises in the field of cross-border e-commerce logistics through the continuous optimization of global warehousing and distribution network and logistics supply chain, providing services for tens of thousands of cross-border e-commerce sellers worldwide to go abroad. In the future, Rush will continue to develop and provide cross-border sellers with one-stop and cargo consolidation and distribution, air-sea-land intermodal transportation, customs clearance, overseas warehousing, and end-distribution segmented global logistics solutions.',
    '_detail_zh-cn':
      '{急速国际物流}（集团）有限公司2010年注册于中国香港，2011年在苏州成立运营总部，是一家专注跨境电商领域的物流服务企业。成立至今，急速已在全国拥有20+家分支机构，在北美、欧洲主要国家与地区设立海外仓，与亚马逊、Wal-Mart、Shopify、Wish、Aliexpress、eBay 、Shopee、阿里国际等各大平台合作密切。同时，急速已与东航、南航、美航等30多家知名航司达成战略合作，拥有Matson、COSCO、中铁及各国邮政等核心资源。急速以技术创新为核心驱动力，通过全球仓配网络与物流供应链的不断优化，已成长为跨境电商物流领域有影响力的企业之一，为全球数万跨境电商卖家出海提供服务。未来，急速将不断开拓，为跨境卖家提供一站式及货物集配、空海陆联运头程、清关、海外仓储、末端配送分段式全球物流方案。',
    '_detail_zh-hk':
      '{急速國際物流}（集團）有限公司2010年註冊於中國香港，2011年在蘇州成立運營總部，是一家專註跨境電商領域的物流服務企業。成立至今，急速已在全國擁有20+家分支機構，在北美、歐洲主要國家與地區設立海外倉，與亞馬遜、Wal-Mart、Shopify、Wish、Aliexpress、eBay 、Shopee、阿裏國際等各大平臺合作密切。同時，急速已與東航、南航、美航等30多家知名航司達成戰略合作，擁有Matson、COSCO、中鐵及各國郵政等核心資源。急速以技術創新為核心驅動力，通過全球倉配網絡與物流供應鏈的不斷優化，已成長為跨境電商物流領域有影響力的企業之一，為全球數萬跨境電商賣家出海提供服務。未來，急速將不斷開拓，為跨境賣家提供一站式及貨物集配、空海陸聯運頭程、清關、海外倉儲、末端配送分段式全球物流方案。'
  },
  {
    key: '191040',
    _name: 'TENGXIN',
    '_name_zh-cn': '腾信物流',
    '_name_zh-hk': '騰信物流',
    _showUrl: 'http://www.txfba.com',
    _url: 'http://www.txfba.com',
    _detail:
      'Everything we do is based on our belief in our ability to advance global trade. We believe that through our efforts and innovations, we can bring the world closer together. We offer high-cost-performance logistics products, including stable and efficient China-US sea freight, China-US air freight, and China-Europe sea freight solutions.<br/>Through continuous optimization, over the past ten years, we have become contracted agents for China Eastern Airlines, Southern Airlines, Air China, MATSON, ZIM, COSCO, CMA, and EMC. To be closer to our clients, we have established subsidiaries in ten major export cities across China (Yiwu, Shenzhen, Ningbo, Guangzhou, Quanzhou, Qingdao, Hangzhou, Taizhou, Changsha, Hefei) and have set up overseas warehouses in Los Angeles and New York.<br/>For an enhanced customer experience, we have independently developed a fully visible digital logistics system. This is how we create secure and stable logistics products, continuously innovating. Our products have been recognized by SF Express, JD Logistics, Deppon, Huamao, TEMU, and YTO International.',
    '_detail_zh-cn':
      '我们所做的一切都基于我们相信自己可以促进世界贸易发展的信念。<br/>我们相信，通过我们的努力和创造会让世界越来越近。<br/>我们打造时效稳定，操作简单的中美海派，中美空派，中欧海派等高性价比物流产品。<br/>通过不断的优化，我们十年来陆续成为东航，南航，国航，MATSON,ZIM，COSCO，CMA,EMC的签约代理.<br/>为了能离客户更近，我们在全国十个主要出口城市（义乌，深圳，宁波，广州，泉州，青岛，杭州，台州，长沙，合肥）设立分子公司，同时在洛杉矶，纽约设立海外仓。<br/>为了更好的客户体验我们自主研发了全链路可视的数字化物流系统.<br/>我们就是这样创作出安全稳定的物流产品，甚至进行不断的革新，我们的产品先后得到了顺丰，京东，德邦，华贸，TEMU，圆通国际的认可。',
    '_detail_zh-hk':
      '我們所做的壹切都基於我們相信自己可以促進世界貿易發展的信念。<br/>我們相信，通過我們的努力和創造會讓世界越來越近。<br/>我們打造時效穩定，操作簡單的中美海派，中美空派，中歐海派等高性價比物流產品。<br/>通過不斷的優化，我們十年來陸續成為東航，南航，國航，MATSON,ZIM，COSCO，CMA,EMC的簽約代理.<br/>為了能離客戶更近，我們在全國十個主要出口城市（義烏，深圳，寧波，廣州，泉州，青島，杭州，臺州，長沙，合肥）設立分子公司，同時在洛杉磯，紐約設立海外倉。<br/>為了更好的客戶體驗我們自主研發了全鏈路可視的數字化物流系統.<br/>我們就是這樣創作出安全穩定的物流產品，甚至進行不斷的革新，我們的產品先後得到了順豐，京東，德邦，華貿，TEMU，圓通國際的認可。'
  }
  // {
  //   key: '190274',
  //   _name: 'JiaYou Exp',
  //   '_name_zh-cn': '佳邮国际',
  //   '_name_zh-hk': '佳邮国际',
  //   _showUrl: 'http://www.jiayouexp.com/?contact/',
  //   _url: 'http://www.jiayouexp.com/?contact/',
  //   _detail:
  //     'Jiayou Exp established in 2016, She is an integrated supply chain logistics service provider with self operated cross-border end-to-end performance capabilities from China to Canada. We are committed to providing customers with one-stop cross-border supply chain solutions including domestic collection, warehousing, customs declaration and inspection, cross-border transportation, overseas customs clearance, warehousing and distribution, and overseas after-sales service.<br/>The company&rsquo;s headquarters is located in Shenzhen, China. Having 12 subsidiaries both domestically and internationally. The domestic branches are located in Guangzhou, Shanghai, Yiwu, Qingdao, and Hong Kong; Overseas branches are located in Vancouver, Toronto, Montreal, Edmonton, Calgary, Ottawa and other major port cities.',
  //   '_detail_zh-cn':
  //     '佳邮国际，成立于2016年，是一家拥有中国到加拿大自营跨境端到端履约能力的一体化供应链物流服务商，致力于为客户提供包括国内揽收、仓储、报关报检、跨境运输、海外清关仓储和配送，海外售后等在内的一站式跨境供应链解决方案。<br/>公司总部设立在中国深圳，在海内外成立12家分公司，国内分公司分布在广州，上海，义乌，青岛，香港；海外分公司分布在温哥华，多伦多，蒙特利尔，埃德蒙顿，卡尔加里，渥太华等主要口岸城市。',
  //   '_detail_zh-hk':
  //     '佳郵國際，成立於2016年，是一家擁有中國到加拿大自營跨境端到端履約能力的一體化供應鏈物流服務商，致力於為客戶提供包括國內攬收、倉儲、報關報檢、跨境運輸、海外清關倉儲和配送，海外售後等在內的一站式跨境供應鏈解決方案。<br/>公司總部設立在中國深圳，在海內外成立12家分公司，國內分公司分布在廣州，上海，義烏，青島，香港；海外分公司分布在溫哥華，多倫多，蒙特利爾，埃德蒙頓，卡爾加裏，渥太華等主要口岸城市。'
  // }
]

export const COOP_CARRIER_ORDER = [
  '190008',
  '190002',
  '191360',
  '190208',
  '190172',
  '190200',
  '190199',
  '190086',
  '190085',
  '190194',
  '191040',
  '100011',
  '1000005'
]

/**
 * 获取中国地区合作品牌标签
 * @returns
 */
export const getCnCoopCarriers = () => {
  return COOP_CARRIER_ORDER.map((item) => {
    return COOP_CARRIERS_LIST.find((val) => val.key === item)
  })
}
/**
 *@Description: 返回是否是用到旧合作商的国家,[undefined, 'CN', 'CA', 'SA']
 *@param {String} str counrty 国家字符串
 *@return boolean
 */
export function coopCarrierCountry(country: string): boolean {
  return !['US'].includes(country)
}

// 品牌标签配置
export const COOP_CARRIER_CONFIG = {
  items: {
    Europe: [[], [], []],
    NorthernAmericaCA: [[], [], []],
    NorthernAmericaUS: [[], [], []],
    default: [
      ['190008', '190002', '190271'],
      ['190208', '190172', '190200', '100011'],
      ['190086', '190085', '190047', '191040', '190199', '1000005']
    ],
    SaudiArabia: [[], [], []],
    Brazil: [[], [], []],
    Australia: [[], [], []],
    Others: [[], [], []]
  },
  conditions: [
    {
      country: [
        'BY',
        'BG',
        'CZ',
        'HU',
        'PL',
        'MD',
        'RO',
        'RU',
        'SK',
        'UA',
        'AX',
        'GG',
        'JE',
        'DK',
        'EE',
        'FO',
        'FI',
        'IS',
        'IE',
        'IM',
        'LV',
        'LT',
        'NO',
        'SJ',
        'SE',
        'GB',
        'AL',
        'AD',
        'BA',
        'HR',
        'GI',
        'GR',
        'VA',
        'IT',
        'MT',
        'ME',
        'MK',
        'SM',
        'RS',
        'SI',
        'ES',
        'AT',
        'BE',
        'FR',
        'DE',
        'LI',
        'LU',
        'MC',
        'NL',
        'PL',
        'CH'
      ],
      item: 'Europe'
    },
    {
      country: ['CA'],
      item: 'NorthernAmericaCA'
    },
    {
      country: ['US'],
      item: 'NorthernAmericaUS'
    },
    {
      country: ['BR'],
      item: 'Brazil'
    },
    {
      country: ['SA'],
      item: 'SaudiArabia'
    },
    {
      country: ['AU'],
      item: 'Australia'
    },
    {
      country: ['CN'],
      item: 'default'
    },
    {
      country: [],
      item: 'Others'
    }
  ]
}
